html {
  font-size: $font-size-base;

  @media #{$md} {
     font-size: 14px;
}
}

body {
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
  @include transition(.2s);
}

strong, .bold {
	font-weight: 700;
}

// .testimonials blockquote p:before {
//   content: "\f10d";
//   font-family: 'Fontawesome';
//   float: left;
//   margin-right: 10px;
// }

// @media #{$xs} {
//     padding-top: 50px;
//   }
//   
//   h1, h2, h3, h4, h5, h6 {
//   font-weight: 300;
// }
// 
@media #{$xs} {
  html, body {
    font-size: 12px;
  }
}


@media (max-height: 650px) and (min-width: 991px) {
  .home {
    padding-top: calc(90px + 25px) !important;

    .titulo {
      font-size: 22px;
    }
    .line-top {
      line-height: 1;
      margin-bottom: -20px;
    }
  }
}