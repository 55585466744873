// BG COLORS

.bg-gray {
  background: #e8e8e8;
}

.bg-orange {
  background-color: $orange;
}

.bg-brown {
  background-color: $brown;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-yellow-dark {
  background-color: $yellow-dark;
}

// COLORS

.gray {
  color: #e8e8e8;
}

.white {
  color: #fff;
}

.green {
  color: $green;
}

.orange {
  color: $orange;

  &:hover, &:focus {
    color: $orange;
  }
}

.red {
  color: $red;

  &:hover, &:focus {
    color: $red;
  }
}

.brown {
  color: $brown;
}

.yellow {
  color: $yellow;
}

.yellow-dark {
  color: $yellow-dark;

  &:hover, &:focus {
    color: $yellow-dark;
  }
}

// MARGIN

.mb-0 {
  margin-bottom: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 5px;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.ml-2 {
  margin-left: 10px;
}

.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mt-6 {
  margin-top: 30px;
}

.mb-6 {
  margin-bottom: 30px;
}

// PADDING

.py-1 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-4 {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.px-1 {
  padding-left: 5px;
  padding-right: 5px;
}

.px-2 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-3 {
  padding-left: 15px;
  padding-right: 15px;
} 

// UTILITIES

.table {
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.d-b {
  display: block;
}

.v-n {
  visibility: hidden;
}

.f-n {
  float: none;
}

.align-top {
  vertical-align: top;
}

.outline {
  border: 1px solid #f09;
}

.full-width {
  width: 100%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.inline-block {
  display: inline-block;
}

.rotate-90 {
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: top;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.lh-0 {
  line-height: 0;
}

.vh-100 {
  height: 100vh;
  max-height: 720px;

  @media #{$sm} {
    height: auto
  }
}

@media only screen and (max-width : 767px) {
    .box {
        height: auto !important;
    } 
}