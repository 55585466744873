@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900');

.lato {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-italic {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-style: normal;
}


.lato-heavy {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700');

.montserrat {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-semibold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.montserrat-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
}
