//
// Variables
// --------------------------------------------------

// BREAKPOINTS
$lg: (max-width: 1199px); // 1199
$md: (max-width: 991px); // 991
$sm: (max-width: 767px); // 767
$xs: (max-width: 600px); // 544
$xxs: (max-width: 420px);// 420

// UTILITIES

//
// Colors
// --------------------------------------------------

$gray: #e8e8e8;
$yellow: #ffec00;
$yellow-dark: #e4a60e;
$green: #026836;
$brown: #c24b26;
$orange: #e86400;
$red: #d73100;

$text-color: #5d5d5d;

// $blue: #0010c1;
// $blue-light: #7d9eca;
// $bg-gray: #f1f2f2;

// $link-color: $blue-light;
// $link-hover-decoration: $blue;


//
// --------------------------------------------------

// Slick carousel

// $slick-font-path: "./fonts/" !default;
// $slick-font-family: "FontAwesome" !default;
// $slick-loader-path: "../images/" !default;
// $slick-arrow-color: #ffffff !default;
// $slick-dot-color: #ffffff !default;
// $slick-dot-color-active: #ffffff !default;
// $slick-prev-character: "\f104" !default;
// $slick-next-character: "\f105" !default;
// $slick-dot-character: "\f111" !default;
// $slick-dot-size: 12px !default;
// $slick-opacity-default: 0.8 !default;
// $slick-opacity-on-hover: 1 !default;
// $slick-opacity-not-active: 0.5 !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// $font-family-sans-serif: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
// $font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
// $font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: 'Lato', sans-serif;;

$font-size-base: 16px; // Assumes the browser default, typically `16px`
// $font-size-lg:   1.25rem !default;
// $font-size-sm:   .875rem !default;
$font-size-xs:   .75rem !default;

// Basics of a navbar
$navbar-height:                    115px;
$navbar-margin-bottom:             20px;
$navbar-border-radius:             0px;

$navbar-default-link-color:                #fff;
$navbar-default-link-hover-color:          $yellow;
$navbar-default-link-active-color:         $yellow;
$navbar-default-link-active-bg:            transparent;

$navbar-padding-vertical: 25px;

//== Navs

$nav-link-padding:  10px 20px;

//== Buttons

$btn-primary-color:              $red;
$btn-primary-bg:                 $yellow;
$btn-primary-border:             $yellow;

$btn-border-radius-base: 25px;