@mixin transition($duration) {
  -webkit-transition: $duration;
  -moz-transition: $duration;
  -ms-transition: $duration;
  -o-transition: $duration;
  transition: $duration;
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}
