@import "variables";
@import "mixins";
@import "bootstrap/variables";
// @import "src/retina.scss";
// @import "sprite";
@import "layout/general";
@import "layout/fonts";
@import "layout/utilities";
// HEADER
.ls-10 {
  letter-spacing: 10pt;
  font-size: 12px;
  @media #{$sm} {
    letter-spacing: 5pt;
    font-size: 10px;
  }
}

.ls-5 {
  letter-spacing: 5pt;
  font-size: 12px;
  @media #{$sm} {
    letter-spacing: 5pt;
    font-size: 10px;
  }
}

.top-bar {
  @media #{$sm} {
    padding-top: 6px;
    padding-bottom: 5px;
  }
  .close-bar {
    &:hover {
      opacity: 0.8;
    }
  }
}

a {
  &:focus {
    outline: none;
  }
}

.header {
  .navbar {
    z-index: 100;
    border: none;
    padding: 20px 50px;
    box-sizing: border-box;
    min-height: auto;
    border-radius: 0px 0px 15px 15px;
    background: rgba($red, 0.8);
    @media #{$lg} {
      padding: 20px 30px;
    }
    @media #{$md} {
      padding: 20px 20px;
    }
  }
  .logo {
    padding-left: 50px;
    max-width: 370px;
    margin-top: 5px;
    @media #{$lg} {
      padding-left: 0px;
      max-width: 280px;
    }
    @media #{$md} {
      max-width: 180px;
      margin-top: 10px;
    }
    @media #{$sm} {
      max-width: 190px;
      margin-top: 0px;
    }
  }
}

.navbar-toggle, .navbar-toggle:focus, .navbar-toggle:active {
  margin: 5px 0 0 0;
  color: #fff;
  border: none;
  background: transparent !important;
  .icon-bar {
    background: #fff !important;
  }
}

.home {
  background: url('../images/bg-home.jpg') center no-repeat;
  background-size: cover;
  padding-top: calc(#{$navbar-height} + 25px);
  padding-bottom: 70px;
  position: relative;
  @media #{$sm} {
    background: url('../images/bg-home-mobile.jpg') center no-repeat;
    background-size: cover;
    padding-top: calc(70px + 25px);
    padding-bottom: 170px;
  }
  // @media #{$xxs} {
  // background-position: top 0px right -100px;
  // }
  // h2 {
  //     font-size: 1.875rem;
  //     line-height: 1;
  // }
  .sumario {
    @media #{$sm} {
      font-size: 1.25rem;
    }
  }
  .titulo {
    @media #{$sm} {
      font-size: 2.125rem;
    }
  }
  .line-top {
    font-size: 1.125rem;
    // line-height: 1.2;
    border-top: 2px solid $red;
    padding-right: 20px;
    display: inline-block;
    width: 80%;
    @media #{$sm} {
      width: 100%;
    }
  }
  .line-bottom {
    font-size: 1.125rem;
    line-height: 1.2;
    border-bottom: 2px solid $brown;
    padding-right: 20px;
    display: inline-block;
    margin-bottom: -13px;
    padding-bottom: 15px;
    width: 80%;
  }
}

.oferta-home {
  p.especial {
    font-size: 1.125rem;
    margin: 0 0 -15px 0;
    padding: 0px 30px;
    @media #{$sm} {
      margin: 0 0 -10px 0;
      padding: 0px 0px 0px 13px;
    }
  }
}

.ribbon-green {
  width: 347px;
  height: 300px;
  position: relative;
  z-index: 5;
  margin: 0 0 -50px -30px;
  padding: 5px 30px;
  @media #{$md} {
    height: 275px;
    margin: 0 0 -40px -30px;
  }
  @media #{$sm} {
    width: 297px;
    height: 270px;
    padding: 5px 20px;
    margin: 0 auto -20px auto;
  }
  .ahora {
    margin: 35px 0 15px 0;
    @media #{$sm} {
      margin: 40px 0 15px 0;
    }
  }
  .price {
    margin: 0 0;
    line-height: 1;
    .symbol {
      font-size: 3.75rem;
      letter-spacing: -4pt;
      position: absolute;
      top: 0;
      left: 0;
    }
    .number {
      font-size: 7.5rem;
      letter-spacing: -4pt;
      padding: 0 0 0 30px;
      margin: -15px 0 0 0;
      position: relative;
      display: inline-block;
    }
    .decimal {
      font-size: 3.75rem;
      letter-spacing: -4pt;
      position: absolute;
      right: 50px;
      top: -10px;
    }
    .currency {
      font-size: 3.75rem;
      letter-spacing: -4pt;
      margin-left: 5px;
    }
  }
  .line-decline {
    font-size: 1.5rem;
    line-height: 1;
    position: relative;
    margin: -5px 0 5px 0;
    &:after {
      position: absolute;
      content: "";
      background: $yellow;
      width: 80%;
      height: 2px;
      top: 11px;
      left: 10%;
    }
  }
}

.ribbon-yellow {
  width: 307px;
  height: 115px;
  position: relative;
  z-index: 1;
  padding: 35px 20px 0px 20px;
  text-align: center;
  @media #{$md} {
    padding: 20px 10px 0px 10px;
    width: 282px;
  }
  @media #{$sm} {
    width: 252px;
    padding: 30px 20px 0px 20px;
  }
  @media #{$sm} {
    background: $yellow !important;
    width: 100%;
    padding: 3px 15px 13px 15px;
    height: auto;
  }
  .underline {
    font-size: 20px;
    margin: 7px 0 7px;
    @media #{$sm} {
      font-size: 16px;
    }
  }
  .especial2 {
    font-size: 13px;
    @media #{$sm} {
      font-size: 12px;
      line-height: 1.2;
    }
  }
}

.underline {
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
  &:after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    background: $red;
    bottom: -3px;
    left: 0px;
  }
}

.menu {
  @media #{$sm} {
    width: 100%;
    border: none;
    box-shadow: none;
  }
  ul {
    li {
      &:after {
        @include transition(.2s);
        border-bottom: 2px solid transparent;
        content: "";
        position: absolute;
        width: calc(100% - 40px);
        bottom: 15px;
        left: 20px;
        @media #{$sm} {
          display: none;
        }
      }
      &:hover {
        &:after {
          border-bottom: 2px solid $yellow;
          content: "";
          position: absolute;
          width: calc(100% - 40px);
          bottom: 15px;
          left: 20px;
        }
      }
      &.btn-wrapper:hover:after {
        display: none;
      }
      &.active {
        a {
          &:after {
            border-bottom: 2px solid $yellow;
            content: "";
            position: absolute;
            width: calc(100% - 40px);
            bottom: 15px;
            left: 20px;
          }
        }
      }
      a {
        font-size: 0.9375rem;
        @media #{$md} {
          padding: 20px 10px;
        }
        @media #{$sm} {
          padding: 10px 10px;
        }
        &.btn {
          color: $brown;
          font-size: 20px;
          padding: 5px 10px;
          text-transform: capitalize;
          letter-spacing: -1pt;
          margin-top: 15px;
          margin-left: 20px;
          @media #{$md} {
            margin-left: 10px;
            margin-top: 10px;
          }
          &:hover, &:focus {
            color: $brown;
          }
        }
      }
    }
  }
}

.ribbon-green {
  background: url('../images/trazado-2.png') 0 0 no-repeat;
  background-size: contain;
}

.ribbon-yellow {
  background: url('../images/trazado-home.png') 0 0 no-repeat;
  background-size: contain;
}

.benefits {
  .top {
    background: #173617 url('../images/bg-iconos-top.jpg') center repeat-x;
    background-size: cover;
  }
  .content {
    background: url('../images/bg-iconos.jpg') center repeat-x;
    background-size: cover;
  }
}

.features {
  padding: 40px 0px 80px;
  @media #{$sm} {
    padding: 30px 0px 30px;
  }
  li {
    img {
      @media #{$sm} {
        max-width: 140px;
      }
    }
  }
  li:first-child, li:last-child {
    padding-top: 20px;
    @media #{$sm} {
      padding-top: 0px;
    }
  }
  li:not(:last-child) {
    background: url('../images/linea-icon.png') top 40px right no-repeat;
    @media #{$sm} {
      margin-bottom: 20px;
      padding-bottom: 20px;
      background: url('../images/linea-icon-bottom.png') bottom center no-repeat;
    }
  }
  p {
    font-size: 1.625rem;
  }
}

.monjes {
  background: url('../images/bg-monje.jpg') center no-repeat;
  background-size: cover;
  padding: 60px 0px;
  .description {
    font-size: 1.375rem;
  }
  .description2 {
    font-size: 1.125rem;
    @media #{$sm} {
      font-size: 1.375rem;
    }
  }
  .monjes-bottom {
    padding-top: 40px;
    .title {
      font-size: 28px;
      @media #{$sm} {
        font-size: 24px;
      }
    }
    .desc {
      margin: 0 0;
      font-size: 1.125rem;
    }
    img {
      margin-top: -30px;
    }
  }
}

.promo {
  background: url('../images/bg-promo.jpg') center no-repeat;
  background-size: cover;
  padding: 100px 0 0 0;
  @media #{$lg} {
    padding: 50px 0 0 0;
  }
  .frascos {
    @media #{$sm} {
      margin: 50px -15px 0 -15px;
    }
  }
  .title {
    background: url('../images/parentesis-envio-gratis.png') center no-repeat;
    background-size: contain;
    padding: 15px 25px;
    font-size: 2.625rem;
    margin-bottom: 3.125rem;
    @media #{$lg} {
      font-size: 2.2rem
    }
    @media #{$sm} {
      font-size: 1.7rem;
    }
  }
  .envio-gratis {
    margin-bottom: 20px;
    @media #{$sm} {
      max-width: 280px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.beneficios {
  background: $gray url('../images/bg-piedras-top.png') top center no-repeat;
  background-size: 100% auto;
  padding-top: 70px;
  padding-bottom: 50px;
  .item {
    @media #{$sm} {
      margin-bottom: 25px;
    }
  }
  .icon {
    position: relative;
    img {
      @media #{$sm} {
        max-width: 90px;
        max-height: 90px;
      }
    }
  }
  .rotate-90 {
    text-align: left;
    line-height: 1;
    padding-left: 25px;
    position: absolute;
    white-space: nowrap;
    bottom: 60px;
    &.right {
      right: -110px;
    }
    &.left {
      left: -120px;
    }
    img {
      position: absolute;
      left: 0;
    }
  }
  .space-top {
    margin-top: 170px;
    @media #{$md} {
      margin-top: 0px;
    }
  }
  .space-top-2 {
    margin-top: 70px;
    @media #{$md} {
      margin-top: 20px;
    }
  }
  .space-bottom-2 {
    margin-bottom: 70px;
    @media #{$md} {
      margin-bottom: 20px;
    }
  }
  .subtitle {
    font-size: 1.625rem;
  }
  .desc {
    font-size: 1.125rem;
    margin-bottom: 90px;
    @media #{$sm} {
      margin-bottom: 50px;
    }
  }
  .container {
    background: url('../images/bg-piedras.jpg') 0 300px no-repeat;
    .row-last {
      background: url('../images/bg-piedras.jpg') right bottom;
      background-repeat: no-repeat;
    }
  }
  .items-container {
    top: 90px;
    // .item .icon {
    //   position: relative;
    //   &:after {
    //     content: "";
    //     position: absolute;
    //     pointer-events: none;
    //     background-size: 100% auto;
    //     left: 0;
    //     top: 0;
    //     background-size: cover;
    //   }
    // }
    // .item-1 .icon:after {
    //     background: url('../images/linea-4.png') bottom center no-repeat;
    //   width: 73px;
    //     height: 235px;
    // }
    // .item-2 .icon:after {
    //   background: url('../images/linea-2.png') bottom center no-repeat;
    //   width: 69px;
    //     height: 433px;
    // }
    // .item-3 .icon:after {
    //   background: url('../images/linea-3.png') bottom center no-repeat;
    //   width: 63px;
    //     height: 2px;
    // }
    // .item-4 .icon:after {
    //   background: url('../images/linea-1.png') bottom center no-repeat;
    //     width: 253px;
    //     height: 496px;
    // }
    // .item-6 .icon:after {
    //   background: url('../images/linea-5.png') bottom center no-repeat;
    //   width: 93px;
    //     height: 272px;
    // }
    // .item-7 .icon:after {
    //   background: url('../images/linea-6.png') bottom center no-repeat;
    //   width: 110px;
    //     height: 141px;
    // }
    // .item-8 .icon:after {
    //   background: url('../images/linea-7.png') bottom center no-repeat;
    //   width: 62px;
    //     height: 2px;
    // }
    // .item-9 .icon:after {
    //   background: url('../images/linea-8.png') bottom center no-repeat;
    //   width: 110px;
    //     height: 330px;
    // }
  }
  .envio-gratis-2 {
    margin-left: auto;
    margin-right: auto;
  }
  .bottommsg {
    margin: 40px 0px 50px;
    display: inline-block;
    line-height: 1.3;
    @media #{$sm} {
      font-size: 26px;
      margin: 10px 0px 30px;
    }
    span {
      background: $yellow-dark;
      padding: 0px 10px;
    }
  }
  .main-title {
    // margin-bottom: 45px;
    //     &:after {
    //     background: $green;
    // }
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: url('../images/bg-piedras-bottom.png') bottom center no-repeat;
    background-size: 100% auto;
    left: 0;
    top: 0;
  }
}

.main-title {
  font-size: 3.875rem;
  margin-bottom: 45px;
  @media #{$sm} {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  &:after {
    content: "";
    width: 30%;
    height: 3px;
    position: absolute;
    background: #c24b26;
    bottom: -10px;
    left: 35%;
  }
}

.pictures {
  background-color: #f8a700;
  padding-top: 75px;
  @media #{$sm} {
    padding-top: 15px;
  }
  .content {
    background-color: #f39500;
    @media #{$sm} {
      background: transparent;
    }
  }
  .icon-top {
    margin-top: -30px;
    margin-bottom: 45px;
  }
  .pics {
    background: #ed8000;
    padding-bottom: 60px;
    @media #{$sm} {
      background: transparent;
      padding-bottom: 15px;
    }
    .slick-list {
      margin: 0 -10px 0 -10px;
    }
    &:after {
      content: "";
      width: 198px;
      height: 100%;
      background: url('../images/bg-pics.png') 0 0 no-repeat;
      left: -198px;
      ;
      position: absolute;
      top: 0px;
      z-index: 5;
      @media #{$sm} {
        display: none;
      }
    }
    &:before {
      content: "";
      width: 87px;
      height: 58px;
      background: url('../images/bg-pics2.png') 0 0 no-repeat;
      right: 0px;
      ;
      position: absolute;
      bottom: 0px;
      z-index: 5;
      @media #{$sm} {
        display: none;
      }
    }
  }
}

.slick-initialized .slick-slide {
  @media #{$sm} {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.testimonials {
  background: url('../images/bg-bottom-2.jpg') top right no-repeat;
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 70px;
  @media #{$sm} {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .main-title:after {
    background: $yellow-dark;
  }
  .item {
    p {
      line-height: 1.4;
      @media #{$sm} {
        font-size: 16px;
      }
    }
  }
  .author {
    &:after {
      background: $yellow-dark;
      bottom: -10px;
    }
  }
  .icon-bottom {
    margin-top: 60px;
    @media #{$sm} {
      margin-top: 30px;
      width: 60px;
    }
  }
}

.footer {
  background: url('../images/bg-bottom-3.jpg') center no-repeat;
  background-size: cover;
  padding-top: 25px;
  padding-bottom: 25px;
  @media #{$sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .call {
    @media #{$sm} {
      max-width: 240px;
    }
  }
  .parentesis {
    background: url('../images/parentesis-envio-gratis.png') center no-repeat;
    background-size: 100% auto;
    padding: 35px 25px;
    @media #{$sm} {
      padding: 25px 0;
      display: inline-block;
      font-size: 24px;
      max-width: 300px;
    }
  }
  .credits {
    font-size: 0.8125rem;
    color: #e8e8e8;
    letter-spacing: 1pt;
    opacity: 0.6;
    a:hover, a:focus {
      color: #e8e8e8;
    }
  }
}

.shadow {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: url('../images/sombra.png') top center no-repeat;
    background-size: 100% auto;
    left: 0;
    top: 0;
  }
}

.gotop {
  position: absolute;
  top: -20px;
  right: 30px;
}

.menu-footer {
  border-top: 1px solid $yellow-dark;
  border-bottom: 1px solid $yellow-dark;
  padding: 7px 15px;
  margin-top: 35px;
  margin-bottom: 35px;
  @media #{$sm} {
    border-top: none;
    border-bottom: none;
    margin-top: 0;
    margin-bottom: 30px;
  }
  li {
    padding-left: 10px;
    padding-right: 10px;
    @media #{$sm} {
      display: block;
      width: 100%;
    }
    &:not(:last-child) {
      border-right: 1px solid $yellow-dark;
      @media #{$sm} {
        border-right: none;
        border-bottom: 1px solid $yellow-dark;
        margin: 0 0 10px 0;
      }
    }
    a {
      font-size: 1.125rem;
      @media #{$sm} {
        font-size: 18px;
        display: block;
        padding-bottom: 10px;
      }
    }
  }
}

// BUTTONS
.btn-primary {
  @include transition(0s);
  @extend .lato-heavy;
  color: $red;
  padding: 3px 20px;
  border: 3px solid $yellow;
  font-size: 1.625rem;
  background: lighten($yellow, 40%);
  background: -moz-linear-gradient(top, lighten($yellow, 40%) 0%, $yellow 100%);
  background: -webkit-linear-gradient(top, lighten($yellow, 40%) 0%, $yellow 100%);
  background: linear-gradient(to bottom, lighten($yellow, 40%) 0%, $yellow 100%);
  &:hover, &:focus, &:active:focus {
    box-shadow: none;
    border: 3px solid darken($yellow, 5%);
    background: lighten($yellow, 40%);
    outline: none;
    background: -moz-linear-gradient(top, lighten($yellow, 40%) 0%, darken($yellow, 5%) 100%);
    background: -webkit-linear-gradient(top, lighten($yellow, 40%) 0%, darken($yellow, 5%) 100%);
    background: linear-gradient(to bottom, lighten($yellow, 40%) 0%, darken($yellow, 5%) 100%);
  }
}

.btn-default {
  @include transition(0s);
  @extend .lato-heavy;
  color: $yellow;
  padding: 0px 3px;
  border: 3px solid $green;
  font-size: 1.125rem;
  background: #96ae96;
  background: -moz-linear-gradient(top, #96ae96 0%, $green 100%);
  background: -webkit-linear-gradient(top, #96ae96 0%, $green 100%);
  background: linear-gradient(to bottom, #96ae96 0%, $green 100%);
  &:hover, &:focus, &:active:focus {
    box-shadow: none;
    border: 3px solid darken($green, 5%);
    background: #96ae96;
    color: $yellow;
    outline: none;
    background: -moz-linear-gradient(top, #96ae96 0%, darken($green, 5%) 100%);
    background: -webkit-linear-gradient(top, #96ae96 0%, darken($green, 5%) 100%);
    background: linear-gradient(to bottom, #96ae96 0%, darken($green, 5%) 100%);
  }
}

.close-modal {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 1rem;
  background: #f2f2f2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  text-align: center;
  line-height: 1.3;
  border: none;
  z-index: 100;
  &:hover {
    background-color: $orange;
    color: #fff;
    text-decoration: none;
  }
}

@media (max-width: 991px) {
  .container {
    width: 100%;
  }
}

.rotate-hover {
  @include transition(.5s);
  &:hover {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 1;
    transform: scale3d(.97, .97, .97);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(.9, .9, .9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(.97, .97, .97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.hover-action {
  animation-duration: 1s;
  animation-fill-mode: both;
  display: inline-block;
  // animation-iteration-count: infinite;
  &:hover {
    animation-name: bounceIn;
  }
}
